import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home3/Hero";
// import Company from "../sections/home3/Company";
import Feature from "../sections/home3/Feature";
import ContentOne from "../sections/home3/ContentOne";
import ContentTwo from "../sections/home3/ContentTwo";
// import Testimonial from "../sections/home2/Testimonial";
import Pricing from "../sections/home3/Pricing";

const Home3 = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <a href="https://app.intry.io/signup"><button className="btn btn btn-blue-3 header-btn rounded-5">
                Sign up
              </button></a>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero className="bg-default-3 pt-25 pt-lg-33" />
        {/* <Company className="pt-12 pb-9" /> */}
        <Feature className="pt-4 pt-lg-18 pb-15 pb-lg-25" />
        <ContentOne className="pt-27 pt-lg-35 pb-lg-34" />
        <ContentTwo className="pb-13 pb-lg-34" />
        {/* <Review className="bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" /> */}
        
        {/* <Testimonial className="pt-lg-19 pb-12 pb-lg-17" /> */}
        <Pricing className="pt-13 pt-lg-25 pb-8 pb-lg-22 " />
      </PageWrapper>
    </>
  );
};
export default Home3;
