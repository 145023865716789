import React, { useRef } from "react";
import Card from "../../components/FreatureCard";
import Slider from "react-slick";
import icon from "../../assets/image/svg/node.svg";
import icon2 from "../../assets/image/svg/chart-growth.svg";
import icon3 from "../../assets/image/svg/chat-45.svg";
import icon4 from "../../assets/image/svg/chat-45-2.svg";
import icon5 from "../../assets/image/svg/connect.svg";
import Modal from "../../components/ModalVideo/Modal";

const Feature = ({ className, ...rest }) => {
  const elSlider = useRef();
  const sliderSettings = {
    autoplay: true,
    centerMode: true,
    centerPadding: "17%",
    slidesToShow: 3,
    arrows: false,
    touchMove: true,
    infinite: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          centerPadding: "10px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          centerPadding: "80px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          centerPadding: "10%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          centerPadding: "30px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  const featureCards = [
    {
      image: icon,
      title: "Organization Goals",
      text:
        "Goals targeted by the organization, OKRs, Trackers and Analytics.",
    },
    {
      image: icon2,
      title: "Team Objectives",
      text:
        "Track and manage team objectives to align with organizational goals.",
    },
    {
      image: icon3,
      title: "Personal Outcomes",
      text:
        "Daily entry of personal outcomes on each team to enhance transparency.",
    },
    {
      image: icon4,
      title: "Real Time Analytics",
      text:
        "Goals, Objectives and ORKs effort tracked automatically with analytics.",
    },
    {
      image: icon5,
      title: "Jira Integration",
      text:
        "Out of the box integration with Jira to tag tickets on the entire system.",
    },
  ];

  return (
    <div className={className} {...rest}>
      {/* feature section */}
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div
              className="text-center mb-8 mb-lg-14"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h2 className="font-size-10 mb-6 letter-spacing-n83">
                Solution made for you
              </h2>
              <p className="font-size-6 mb-0">
                With a lot of features specifically created to help your organization achieve its goals with data driven decisions. 
              </p>
            </div>
          </div>
        </div>
        {/* End Section Title */}
      </div>
      {/* feature slider */}
      <div className="row">
        <div className="col-12" data-aos="zoom-in" data-aos-delay={800}>
          {/* <div className="">
            </div> */}
          <Slider
            ref={elSlider}
            {...sliderSettings}
            className="l3-feature-slider d-flex"
          >
            {featureCards.map(({ image, title, text }, index) => {
              return (
                <Card image={image} title={title} text={text} key={index} />
              );
            })}
          </Slider>
        </div>
      </div>
      {/* End feature slider */}
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className="mt-10 mt-lg-12 text-center"
            data-aos="zoom-in"
            data-aos-delay={1000}
          >
            <Modal className="ml-2">
            <a
              className="video-btn btn btn-blue-3 btn-2 rounded-5"
            >
              <i className="fa fa-play-circle mr-5" /> See intry.io in action
            </a>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
