import React from "react";

import DashBoardImage from "../../assets/image/home-3/png/dashboard.png";
import YellowShape from "../../assets/image/home-3/png/goal-analytics.png";
import GreenShape from "../../assets/image/home-3/png/outcomes-history.png";

const Hero = ({className,...rest}) => {
    return (
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9">
              <div
                className="text-center mb-11 mb-lg-24"
                data-aos="zoom-in"
                data-aos-delay={500}
              >
                <h1 className="font-size-11 mb-7">
                 Get control over your company goals
                </h1>
                <p className="font-size-7 px-lg-5 mb-0">
                  with absolute visibility, organization members contribution with real time analytics on OKRs progress
                </p>
                <div className="btn-group d-block mt-10">
                <a href="https://www.producthunt.com/posts/intry-io?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-intry&#0045;io" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=367545&theme=light" alt="intry&#0046;io - Get&#0032;control&#0032;over&#0032;your&#0032;company&#0032;goals | Product Hunt" style={{width: 250, height: 54}} width="250" height="54" /></a>
                </div><div className="btn-group d-inline-block mt-10">
                  <a className="btn btn-blue-3 btn-2 rounded-5" href={"https://app.intry.io/signup"}>
                    Get Started for Free
                  </a>
                  <p className="d-block mt-6 font-size-3 mb-0 text-bali-gray letter-spacing-reset">
                    No credit card required
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Hero Image Group */}
          <div className="row justify-content-center">
            <div className="col-md-10 col-xs-11">
              <div className="l3-hero-image-group w-100">
                {/* Image One */}
                <div
                  className="img-1 shadow-13"
                  data-aos="fade-up"
                  data-aos-delay={500}
                  data-aos-once="true"
                >
                  <img src={DashBoardImage} alt="" style={{borderRadius: 8}}/>
                  {/* Image Two */}
                  <div className="img-2" style={{zIndex: 20}}>
                    <img
                    className="w-100 mw-100"
                    style={{width: 250, borderRadius: 8, transform: `rotate(-8deg)`, boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`}}
                      src={YellowShape}
                      alt=""
                      data-aos="zoom-in-right"
                      data-aos-duration="750"
                      data-aos-delay={700}
                      data-aos-once="true"
                    />
                  </div>
                  {/* Image Three */}
                  <div className="img-3" style={{zIndex: 20}}>
                    <img
                    className="w-100 mw-100"
                    style={{width: 300, borderRadius: 8, transform: `rotate(6deg)`, boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`}}
                      src={GreenShape}
                      alt=""
                      data-aos="zoom-in-left"
                      data-aos-duration="750"
                      data-aos-delay={900}
                      data-aos-once="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Hero Image Group */}
        </div>
      </div>
    );
}


export default Hero;