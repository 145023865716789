import React from "react";
import { Link } from "gatsby";
import imageOne from "../../assets/image/home-3/png/outcomes.png";
import imageTwo from "../../assets/image/home-3/png/outcomes-card.png";
import imageThree from "../../assets/image/home-3/png/big-green-circle-img.png";

import iconApple from "../../assets/image/home-3/png/apple-button.png";
import iconGoogle from "../../assets/image/home-3/png/google-play-button.png";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-xs-9"
            data-aos="fade-right"
            data-aos-delay={500}
          >
            <div className="l3-content-image-5 mt-5 mt-lg-0">
              <div className="img-1">
                <img className="w-100" src={imageOne} alt="" style={{borderRadius: 8}} />
              </div>
              <div className="img-2">
                <img className="w-100" src={imageTwo} alt="" style={{borderRadius: 8, transform: `rotate(10deg)`, boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`}} />
              </div>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-7 col-xs-10"
            data-aos="fade-left"
            data-aos-delay={500}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-8">
                Team and Personal outcomes
              </h2>
              <p className="font-size-6 mb-0">
                Record completed and pending outcomes for each member of the team, in relation to specific goals to track progress.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
